/* ---------------------- Core Styling ---------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F7FD;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar */
.navbar {
  background-color: #0085FF;
  color: #fff;
  padding: 15px 40px;
}

/* ---------------------- Main page ---------------------- */
.mainContainer {
  height: auto;
  margin: 10px 40px;
  /* border: 1px solid red; */
}

/* Core Stats */
.coreStats {
  height: 100%;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.coreStats > div {
  height: auto;
  width: 100%;
  /* border: 1px solid red; */
  margin: 10px;
  /* padding: 7px; */
}

.coreStatLabel {
  font-size: larger;
  border-bottom: 1px solid #0085FF;
  background-color: #0085FF;
  color: #fff;
  padding: 3px;
  text-align: center;
}

.coreStatAmount {
  text-align: center;
  padding: 5px;
}

/* Price and Marketcap Charts */
.priceChartContainer {
  height: 400px;
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 60px;
}

.priceGraph {
  display: flex;
  align-items: center;
  height: 100%;
  width: 50%;
}

.priceFiatGraph {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.priceStats {
  height: 10%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}


/* Supply Stats Styling */
.supplyChartContainer {
  height: 100%;
  /* border: 1px solid orange; */
  height: auto;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
  align-items: center;
}

.emissionsChart {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.currentSupplyCharts {
  width: 100%;
  /* border: 4px solid pink; */
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.mainSupplyHeaders {
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.mainSupplyHeaders > div {
  margin: 10px;
}

.currenSupplyHeaders {
  width: 100%;
  margin: 10px 5px;
  margin-bottom: 60px;
  /* border: 1px solid brown; */
}

.currentDistributions {
  width: 50%;
  height: 100%;
}

.currentInflationRates{
  width: 50%;
  height: 100%;
}

.supplyCurves {
  margin-bottom: 60px;
}

.emissionsDoughnutGraph, .emissionsBarChart {
  height: 100%;
  width: 100%;
  margin: 5px;
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.doughtnutHeader {
  margin-bottom: 10px;
}

.emissionsBarChart {
  height: 330px;
}

.emissionsStats {
  width: auto;
  height: auto;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-evenly; */
  align-items: center;
  margin-bottom: 30px;
}

.emissionsStats > div {
  width: 100%;
  /* border: 1px solid red; */
  margin: 10px;

}

.groupedSupplyStats {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
}

.supplyDoughnutHeader {
  /* text-align: center; */
  margin-bottom: 5px;
}

.supplyDoughnutSubHeader {
  /* text-align: center; */
  margin-bottom: 20px;
}

.innerCardDiv {
  padding: 15px;
}





/* ---------------------- Claim Metrics Page ---------------------- */
.coreClaimGraphs {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.coreClaimGraphs > div {
  width: 100%;
  height: 100%;
  margin: 5px;
}

.averageClaimsGraph {
  margin-bottom: 40px;
}

.successfulAverages {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}


/* OneSignal */

.urlGraph {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
}

.notificationChartContainer {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 60px;
}